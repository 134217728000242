@use "sass:math";

.loader{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100000000;
    background-color: white;
    flex-flow: column;
    opacity: 1;
    visibility: visible;
    transition: 0.4s opacity;

    .wrapperMain {
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
        width: 120px;
        height: 120px;
        margin-left: calc(50vw - 60px);
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }
    }

    @keyframes grow {
        0%, 15%, 100% { transform:scale(1); }
        5% { transform:scale(1.2); }
    }

    @mixin on-circle($circle-size, $item-size) {
        position: relative;
        width:  $circle-size;
        height: $circle-size;
        border-radius: 50%;

        > * {
            display: block;
            position: absolute;
            top:  50%;
            left: 50%;
            width:  $item-size;
            height: $item-size;
            margin: math.div($item-size, -2);

            $angle: 45;
            $rot: 0;

            @for $i from 1 through 8 {
                &:nth-of-type(#{$i}) {
                    transform:
                            rotate($rot * 1deg)
                            translate(math.div($circle-size , 2))
                            rotate($rot * -1deg);
                }
                &:nth-of-type(#{$i}) > .bubble {
                    animation: grow 4s ease infinite;
                    animation-delay: ($i - 1.2) * .5s
                }
                $rot: $rot + $angle;
            }
        }
    }

    .wrapper {
        @include on-circle($circle-size: 7.5rem, $item-size: 1.5rem);
        filter:url('#goo');
    }

    .bubbleWrap {
        max-width: 100%;

        .bubble {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #00314f;
        }
    }

    .satWrap {
        animation: spin 4s linear infinite;

        .sat {
            width: 1.125em;
            height: 1.125em;
            background: #f29400;
            border-radius: 50%;
            margin-left: 3.9375em;
            box-shadow: 0px 0px 50px -4px #f29400;
            margin-top: 3px;
        }
    }

    .satWrapBuilder {
        animation: unset;
    }
}

.loaderHide {
    opacity: 0;
}

@media screen and (max-width: 600px) {
    .loader{

        .wrapperMain {
            transform: scale(.7);
        }
    }
}