.loader {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 100000000;
  background-color: white;
  flex-flow: column;
  opacity: 1;
  visibility: visible;
  transition: 0.4s opacity;
}
.loader .wrapperMain {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  width: 120px;
  height: 120px;
  margin-left: calc(50vw - 60px);
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes grow {
  0%, 15%, 100% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.2);
  }
}
.loader .wrapper {
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  filter: url("#goo");
}
.loader .wrapper > * {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: -0.75rem;
}
.loader .wrapper > *:nth-of-type(1) {
  transform: rotate(0deg) translate(3.75rem) rotate(0deg);
}
.loader .wrapper > *:nth-of-type(1) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: -0.1s;
}
.loader .wrapper > *:nth-of-type(2) {
  transform: rotate(45deg) translate(3.75rem) rotate(-45deg);
}
.loader .wrapper > *:nth-of-type(2) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 0.4s;
}
.loader .wrapper > *:nth-of-type(3) {
  transform: rotate(90deg) translate(3.75rem) rotate(-90deg);
}
.loader .wrapper > *:nth-of-type(3) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 0.9s;
}
.loader .wrapper > *:nth-of-type(4) {
  transform: rotate(135deg) translate(3.75rem) rotate(-135deg);
}
.loader .wrapper > *:nth-of-type(4) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 1.4s;
}
.loader .wrapper > *:nth-of-type(5) {
  transform: rotate(180deg) translate(3.75rem) rotate(-180deg);
}
.loader .wrapper > *:nth-of-type(5) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 1.9s;
}
.loader .wrapper > *:nth-of-type(6) {
  transform: rotate(225deg) translate(3.75rem) rotate(-225deg);
}
.loader .wrapper > *:nth-of-type(6) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 2.4s;
}
.loader .wrapper > *:nth-of-type(7) {
  transform: rotate(270deg) translate(3.75rem) rotate(-270deg);
}
.loader .wrapper > *:nth-of-type(7) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 2.9s;
}
.loader .wrapper > *:nth-of-type(8) {
  transform: rotate(315deg) translate(3.75rem) rotate(-315deg);
}
.loader .wrapper > *:nth-of-type(8) > .bubble {
  animation: grow 4s ease infinite;
  animation-delay: 3.4s;
}
.loader .bubbleWrap {
  max-width: 100%;
}
.loader .bubbleWrap .bubble {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #00314f;
}
.loader .satWrap {
  animation: spin 4s linear infinite;
}
.loader .satWrap .sat {
  width: 1.125em;
  height: 1.125em;
  background: #f29400;
  border-radius: 50%;
  margin-left: 3.9375em;
  box-shadow: 0px 0px 50px -4px #f29400;
  margin-top: 3px;
}
.loader .satWrapBuilder {
  animation: unset;
}

.loaderHide {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .loader .wrapperMain {
    transform: scale(0.7);
  }
}

